import { useEffect, useRef, useState } from "react";
import "./assets/style/main.css";
import { Areas } from "./components/Areas";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Intro } from "./components/Intro";
import { IntroBanner } from "./components/IntroBanner";
import { Products } from "./components/Products";
import { Services } from "./components/Services";
import SmoothScrollbar from "smooth-scrollbar";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import { Terms } from "./components/Terms";

export const App = () => {
  const introRef = useRef();
  const productsRef = useRef();
  const areasRef = useRef();
  const contactRef = useRef();
  const servicesRef = useRef();
  const appRef = useRef();

  useEffect(() => {
    const options = {
      damping: 0.04,
      thumbMinSize: 20,
      renderByPixels: true,
      alwaysShowTracks: false,
      continuousScrolling: false,
    };

    if (window.innerWidth > 600) {
      SmoothScrollbar.init(appRef.current, options);
    } else {
      SmoothScrollbar.destroy(appRef.current, options);
    }
  }, []);

  const [isTermsElVisible, setTermsElVisibility] = useState(false);
  const [isPrivacyVisible, setPrivacyVisibility] = useState(false);

  const handleFooterLinkClick = () => {
    setTermsElVisibility(!isTermsElVisible);
  };

  const handleFooterPrivacyLinkClick = () => {
    setPrivacyVisibility(!isPrivacyVisible);
  };
  return (
    <>
      <Header ref={{ appRef, introRef, productsRef, areasRef, servicesRef, contactRef }} />
      {isTermsElVisible && <Terms handleClick={handleFooterLinkClick} />}
      {isPrivacyVisible && <PrivacyPolicy handleClick={handleFooterPrivacyLinkClick} />}
      <div ref={appRef} className="app">
        <IntroBanner ref={{ contactRef, appRef }} />
        <Intro ref={{ introRef, contactRef, appRef }} />
        <Products ref={{ productsRef, contactRef, appRef }} />
        <Services ref={{ servicesRef, appRef, contactRef }} />
        <Areas ref={{ areasRef, appRef, contactRef }} />
        <Footer
          ref={contactRef}
          handleFooterLinkClick={handleFooterLinkClick}
          handleFooterPrivacyLinkClick={handleFooterPrivacyLinkClick}
        />
      </div>
    </>
  );
};
