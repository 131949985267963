import "./PrivacyPolicy.css";

export const PrivacyPolicy = ({ handleClick }) => {
  return (
    <div className="privacy">
      <div className="privacy__inner">
        <div className="privacy__inner__cross" onClick={handleClick}>
          <div className="privacy__inner__cross__line"></div>
          <div className="privacy__inner__cross__line"></div>
        </div>
        <div>
          <h1>Privacy Policy</h1>
          <p>As at 06/2023</p>
          <p>
            QUANTUMTECH SYSTEMS LTD is a UK-based marketing agency delivering marketing, creative and digital solutions
            to drive business success. We are committed to protecting and respecting any personal information you share
            with us.
          </p>
          <p>
            This Notice describes the types of personal information we collect from you when you work with us, use our
            website, interact with our digital platforms, when we meet you and when you contact us. It explains how your
            personal information is used by us, when we share it with others and your choices about its use.
          </p>
          <p>
            Where this statement refers to “we”, “our” or “us” below, unless it mentions otherwise, we’re referring to
            QUANTUMTECH SYSTEMS LTD.
          </p>
          <p>Our registered address is, 3rd Floor Suite, 207 Regent Street, London, England, W1B3HH.</p>
          <p>
            We may update this statement from time to time, so you may wish to check this Notice occasionally. If any
            significant changes are made to the way in which we handle and use your personal information we will
            endeavour to notify you directly.
          </p>
          <h2>HOW TO CONTACT US</h2>
          <p>
            Please direct any queries about this Notice or about the way we process your personal information to our
            Data Protection Champion using the postal address above or by emailing us at office@quantumtech.dev. If you
            would prefer to speak to us by phone, please call +44 7537 142868.
          </p>
          <h2>HOW WE COLLECT YOUR PERSONAL INFORMATION</h2>
          <p>
            We may collect information about you whenever you engage and interact with us, whether through our
            website https://quantumtech.dev/ (“our website”), over the telephone, face to face, email, social media or
            other touch points.
          </p>
          <p>
            We may also obtain information about you when you contact us, register for events, if you complete any of
            our surveys conducted for research purposes or if you apply for a job.
          </p>
          <p>The personal information we may collect routinely includes the following:</p>
          <ul>
            <li>Full name</li>
            <li>Job title</li>
            <li>Email address</li>
            <li>Mobile number</li>
            <li>Any information you provide as part of the content of a message</li>
          </ul>
          <p>
            Where we believe you are likely to be interested in our services, we may also obtain your name and business
            contact details from publicly available sources, (for example, information you have allowed to be shared as
            part of your public profile).
          </p>
          <p>
            We will also handle personal information about you if you include personal details within correspondence you
            send to us.
          </p>
          <p>
            In providing our services we do not routinely handle any Special Category Data, and our services are not
            aimed at children.
          </p>
          <p>
            If you are applying for a job with QUANTUMTECH SYSTEMS LTD, you should reference our Applicant Privacy
            Notice (available via our website or on request). We also have a specific privacy notice for our employees.
          </p>
          <h2>HOW WE USE YOUR PERSONAL INFORMATION</h2>
          <p>
            We endeavour to only handle the personal information necessary for our business operations. Outlined below
            are the ways in which we use your personal information and details of our lawful basis for processing under
            UK GDPR.
          </p>
          <h3>To send you marketing communications</h3>
          <p>
            We want to keep in touch with you to provide information about our services and promise we will never sell
            your information to other organisations for their own marketing purposes.
          </p>
          <p>
            We rely on our legitimate interests to send you marketing communications in a business context. We will
            always give you the option not to receive marketing communications from us in future.
          </p>
          <p>
            We include tracking pixels or web beacons in our email newsletters so we can track how many people open our
            emails and click to view certain pages on our website. They help us to understand the effectiveness of our
            email communications.
          </p>
          <p>
            We may contact you with targeted advertising delivered online through social media and platforms operated by
            other companies or use your personal information to tailor marketing to improve its relevance to you, unless
            you object.
          </p>
          <p>
            You can stop receiving marketing from us at any time, by using the unsubscribe mechanism on our emails or by
            contacting us at office@quantumtech.dev. If you would prefer to speak to us by phone, please call +44 7537
            142868.
          </p>
          <h3>To provide our services</h3>
          <p>
            We will handle your personal information where we supply to you, have supplied to you or are in discussions
            about supplying any of our services, or where we have arranged for the supply of another company’s products
            or services to you.
          </p>
          <p>
            We will use this information in connection with providing our services. This will routinely include names
            and business contact information. We process this personal data under the lawful basis of performance of a
            contract, or necessary for taking steps before entering into a contract.
          </p>
          <h3>Where we have a legal obligation</h3>
          <p>
            It may be necessary for us to process your personal information to comply with legal obligations. For
            example, for taxation purposes, fulfilling employment law requirements or other statutory regulations.
          </p>
          <h3>Where we have a legitimate interest</h3>
          <p>
            We will use and process your personal information where it is necessary for us to pursue our legitimate
            interests as a business. Where we rely on legitimate interests, we balance our interests with yours and make
            sure your privacy rights and freedoms are protected.
          </p>
          <p>We rely on our legitimate interests for the following purposes:</p>
          <ul>
            <li>
              to conduct market analysis and research (including contacting you with surveys) so we can better
              understand your needs and provide tailored and bespoke offers and services that we think you will be
              interested in
            </li>
            <li>
              for service and product development purposes (for example to improve our services, offering and
              performance)
            </li>
            <li>
              to administer competitions and promotions that you enter with us from time to time and to distribute
              prizes
            </li>
            <li>
              to analyse, evaluate and improve our services so that your engagement with us is more useful and enjoyable
            </li>
            <li>to correspond or communicate with you, where not strictly necessary as part of a contract</li>
            <li>to verify the accuracy of data we hold about you</li>
            <li>for network and information security</li>
            <li>for prevention of fraud and other criminal activities</li>
            <li>for the management of queries, complaints, or claims</li>
            <li>for the establishment and defense of our legal rights</li>
            <li>to inform you of updates to our terms and conditions and policies</li>
          </ul>
          <p>
            In exceptional circumstances, we may handle your personal information for reasons of public interests or to
            protect your vital interests.
          </p>
          <h2>YOUR RIGHTS</h2>
          <p>
            In addition to your right to not receive marketing (please see above ‘To send you marketing
            communications’), you have other privacy rights under data protection law. To fulfill these rights, we may
            need to ask you to provide proof of your identity. We will acknowledge any request received and provide
            further information, as necessary. Your rights are:
          </p>
          <ul>
            <li>
              Accessing your personal information: You have the right to ask for a copy of your ‘personal data’ (as
              defined under UK GDPR) and details of the purpose(s) we are using it for.
            </li>
            <li>
              Correcting and updating your personal information: You have the right to request that we amend any
              personal data which is incorrect or requires updating.
            </li>
            <li>
              Objecting to our use of your personal information: In certain circumstances, you have the right to object
              to our use of your personal information.
            </li>
            <li>
              Erasing your personal information or restricting its use: You can ask for your personal information to be
              removed from our systems or request the restriction of its use.
            </li>
            <li>
              Transferring your personal information in a structured data file (“data portability”): In specific
              circumstances, you may ask us to provide you, or another organization, with a copy of your personal
              information in a structured file.
            </li>
          </ul>
          <p>
            If you wish to exercise any of the above rights, please contact our Data Protection Champion using the
            contact details provided above. We make every effort to fully respond to any queries or specific requests,
            and please let us know if you feel we have not responded sufficiently.
          </p>
          <p>
            If you are not satisfied with the way in which we have handled your request, you have the right to complain
            to the Information Commissioner’s Office (ICO) if you are concerned about the way we have processed your
            personal information.
          </p>
          <h2>OTHERS WHO MAY RECEIVE OR HAVE ACCESS TO YOUR PERSONAL INFORMATION</h2>
          <p>
            Our suppliers and service providers: We may share your information (or give access to it) with our service
            providers, agents, subcontractors, and other organizations, who act on our behalf to provide our services.
          </p>
          <p>
            Other ways we may share your personal information: We may transfer your personal information to another
            organization as part of a sale of some or all of our business and assets or as part of any business
            restructuring or reorganization. We may also disclose your personal information if we are under a duty to do
            so in order to comply with any legal obligation, to detect or report a crime, to enforce or apply the terms
            of our contracts, or to protect the rights, property or safety of our visitors and customers.
          </p>
          <p>We will always take steps with the aim of ensuring that your privacy rights continue to be protected.</p>
          <h2>WHERE WE STORE YOUR PERSONAL INFORMATION</h2>
          <p>
            All information you provide to us is stored on our secure servers which are located in the UK. If at any
            time we transfer your personal information to, or store it in, countries located outside of the UK, we will
            endeavor to make sure appropriate safeguards are in place for that transfer and storage as necessary, and if
            required, under applicable law.
          </p>
          <p>
            Where we use service providers based outside the UK, we endeavor to make sure this is appropriately covered
            by appropriate safeguards under UK data protection law.
          </p>
          <h2>HOW LONG WE KEEP YOUR PERSONAL INFORMATION FOR</h2>
          <p>
            The length of time we keep your personal information for is determined by a number of factors including the
            purpose for which we use that information, our business needs, and in some cases our obligations under other
            laws.
          </p>
          <p>
            We take necessary steps and endeavor to only keep your personal information for as long as is necessary for
            the purpose(s) we are handling it.
          </p>
          <p>
            If you would like more information concerning our retention periods, please email office@quantumtech.dev. If
            you’d prefer to speak to us, please call +447537142868.
          </p>
          <h2>OUR WEBSITE & LINKS TO OTHER SITES</h2>
          <p>
            Unfortunately, the transmission of information via the internet is not completely secure. Although we will
            do our best to protect your personal information, we cannot guarantee the security of your information when
            and if transmitted to our website and, accordingly, any transmission is at your own risk. Once we have
            received your personal information, we put in place reasonable and appropriate controls to ensure that it
            remains secure against accidental or unlawful destruction, loss, alteration, or unauthorized access.
          </p>
          <p>
            Our website may contain links to other websites run by other organizations. This Notice does not apply to
            those other websites‚ so we encourage you to read their Privacy Notices. We cannot be responsible for the
            privacy policies and practices of other websites even if you access them using links that we provide. In
            addition, if you linked to our website from a third-party website, we cannot be responsible for the privacy
            policies and practices of the owners and operators of that third-party website and recommend that you check
            the Privacy Notice of that third-party website.
          </p>
        </div>
      </div>
    </div>
  );
};
