import "./Terms.css";
export const Terms = ({ handleClick }) => {
  return (
    <div className="terms">
      <div className="terms__inner">
        <div className="terms__inner__cross" onClick={handleClick}>
          <div className="terms__inner__cross__line"></div>
          <div className="terms__inner__cross__line"></div>
        </div>
        <div>
          <h1>Terms And Conditions</h1>
          <p>
            THIS DOCUMENT CONTAINS VERY IMPORTANT INFORMATION REGARDING YOUR RIGHTS AND OBLIGATIONS, AS WELL AS
            CONDITIONS, LIMITATIONS, AND EXCL
          </p>
          <p>
            THESE TERMS REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY
            TRIALS OR CLASS ACTIONS.
          </p>
          <p>
            BY PLACING AN ORDER FOR SERVICES FROM THIS WEBSITE, YOU AFFIRM THAT YOU ARE OF LEGAL AGE TO ENTER INTO THIS
            AGREEMENT, AND YOU ACCEPT AND ARE BOUND BY THESE TERMS AND CONDITIONS. YOU AFFIRM THAT IF YOU PLACE AN ORDER
            ON BEHALF OF AN ORGANIZATION OR COMPANY, YOU HAVE THE LEGAL AUTHORITY TO BIND ANY SUCH ORGANIZATION OR
            COMPANY TO THESE TERMS AND CONDITIONS.
          </p>
          <p>
            YOU MAY NOT ORDER OR OBTAIN SERVICES FROM THIS WEBSITE IF YOU (A) DO NOT AGREE TO THESE TERMS, (B) ARE NOT
            THE OLDER OF (i) AT LEAST 18 YEARS OF AGE OR (ii) LEGAL AGE TO FORM A BINDING CONTRACT WITH OTRIUM OR (C)
            ARE PROHIBITED FROM ACCESSING OR USING THIS WEBSITE OR ANY OF THIS WEBSITE'S CONTENTS, GOODS OR SERVICES BY
            APPLICABLE LAW.
          </p>
          <p>
            These terms and conditions (these "Terms") apply to the purchase and sale of services and services through
            https://quantumtech.dev/ (the "Site"). These Terms are subject to change by QUANTUMTECH SYSTEMS LTD
            (referred to as "us", "we", or "our" as the context may require) without prior written notice at any time,
            in our sole discretion. You should review these Terms prior to purchasing any product or services that are
            available through this Site. Your continued use of this Site after the "Last Updated Date" will constitute
            your acceptance of and agreement to such changes.
          </p>
          <p>
            These Terms are an integral part of the Website Terms of Use that apply generally to the use of our Site.
            You should also carefully review our Privacy Notice before placing an order for services through this Site
            (see 9).
          </p>
          <h2>1. Order Acceptance and Cancellation</h2>
          <p>
            You agree that your order is an offer to buy, under these Terms, all services listed in your order. All
            orders must be accepted by us or we will not be obligated to sell the services to you. We may choose not to
            accept orders at our sole discretion, even after we send you a confirmation email with your order number and
            details of the items you have ordered.
          </p>
          <h2>2. Prices and Payment Terms</h2>
          <ol>
            <li>
              <p>
                We may offer from time to time promotions on the Site that may affect pricing and that are governed by
                terms and conditions separate from these Terms. If there is a conflict between the terms for a promotion
                and these Terms, the promotion terms will govern.
              </p>
            </li>
            <li>
              <p>
                Terms of payment are within our sole discretion. Payment must be received by us before our acceptance of
                an order. We accept paying by bank transfer and Paypal. You represent and warrant that (i) the credit
                information you supply to us is true, correct and complete
              </p>
            </li>
          </ol>
          <h2>3. Returns and Refunds</h2>
          <p>
            All products designated on the Site as final sale is non-returnable. WE OFFER NO REFUNDS ON ANY PRODUCTS
            DESIGNATED ON THIS SITE AS NON-RETURNABLE.
          </p>
          <p>
            ALL SERVICES OFFERED ON THIS SITE ARE PROVIDED “AS IS” WITHOUT ANY WARRANTY WHATSOEVER, INCLUDING, WITHOUT
            LIMITATION, ANY (A) WARRANTY OF MERCHANTABILITY; (B) WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE; OR (C)
            WARRANTY AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY; WHETHER EXPRESS OR IMPLIED
            BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE.
          </p>
          <p>
            SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES SO THE ABOVE
            DISCLAIMER MAY NOT APPLY TO YOU.
          </p>
          <p>YOU AFFIRM THAT WE SHALL NOT BE LIABLE, UNDER ANY CIRCUMSTANCES</p>
          <h2>4. Limitation of Liability</h2>
          <p>
            [WE HAVE BEEN GIVEN ASSURANCES OF PRODUCT SALABILITY, MERCHANTABILITY, AND COMPLIANCE FROM THE
            MANUFACTURERS.] IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR CONSEQUENTIAL, INDIRECT,
            INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR ENHANCED DAMAGES[, LOST PROFITS OR REVENUES OR DIMINUTION IN
            VALUE], ARISING OUT OF, OR RELATING TO, AND/OR IN CONNECTION WITH ANY BREACH OF THESE TERMS, REGARDLESS OF
            (A) WHETHER SUCH DAMAGES WERE FORESEEABLE, (B) WHETHER OR NOT WE WERE ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES AND (C) THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT OR OTHERWISE) UPON WHICH THE CLAIM IS BASED.
          </p>
          <p>
            OUR SOLE AND ENTIRE MAXIMUM LIABILITY [, FOR ANY REASON,] AND YOUR SOLE AND EXCLUSIVE REMEDY [FOR ANY CAUSE
            WHATSOEVER,] SHALL BE LIMITED TO THE ACTUAL AMOUNT PAID BY YOU FOR THE SERVICES YOU HAVE ORDERED THROUGH OUR
            SITE.
          </p>
          <h2>5. Orders Not for Resale or Export</h2>
          <p>
            You represent and warrant that you are buying services from the Site for your own personal or household use
            only, and not for resale.
          </p>
          <h2>6. Privacy</h2>
          <p>
            We respect your privacy and are committed to protecting it. Our Privacy Policy governs the processing of all
            personal data collected from you in connection with your purchase of services through the Site.
          </p>
          <h2>7. Force Majeure</h2>
          <p>
            We will not be liable or responsible to you, nor be deemed to have defaulted or breached these Terms, for
            any failure or delay in our performance under these Terms when and to the extent such failure or delay is
            caused by or results from acts or circumstances beyond our reasonable control, including, without
            limitation, acts of God, flood, fire, earthquake, explosion, governmental actions, war, invasion or
            hostilities (whether war is declared or not), terrorist threats or acts, riot or other civil unrest,
            national emergency, revolution, insurrection, epidemic, pandemic, lockouts, strikes or other labor disputes
            (whether or not relating to our workforce), or restraints or delays affecting carriers or inability or delay
            in obtaining supplies of adequate or suitable materials, materials or telecommunication breakdown or power
            outage.
          </p>
          <h2>8. Dispute Resolution and Binding Arbitration</h2>
          <p>
            YOU AND QUANTUMTECH SYSTEMS LTD ARE AGREEING TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT OR BEFORE A
            JURY, OR TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE ACTION WITH RESPECT TO A CLAIM. OTHER RIGHTS
            THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION.
          </p>
          <p>
            ANY CLAIM, DISPUTE OR CONTROVERSY (WHETHER IN CONTRACT, TORT OR OTHERWISE, WHETHER PRE-EXISTING, PRESENT OR
            FUTURE, AND INCLUDING STATUTORY, CONSUMER PROTECTION, COMMON LAW, INTENTIONAL TORT, INJUNCTIVE AND EQUITABLE
            CLAIMS) BETWEEN YOU AND US ARISING FROM OR RELATING IN ANY WAY TO YOUR PURCHASE OF PRODUCTS OR SERVICES
            THROUGH THE SITE, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION.
          </p>
          <ol>
            <li>
              <p>
                You may elect to pursue your claim in small-claims court rather than arbitration if you provide us with
                written notice of your intention to do so within 60 days of your purchase. The arbitration or
                small-claims court proceeding will be limited solely to your individual dispute or controversy.
              </p>
            </li>
            <li>
              <p>
                You agree to an arbitration on an individual basis. In any dispute, NEITHER YOU NOR QUANTUMTECH SYSTEMS
                LTD WILL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER CUSTOMERS IN COURT OR IN
                ARBITRATION OR OTHERWISE PARTICIPATE IN ANY CLAIM AS A CLASS REPRESENTATIVE, CLASS MEMBER OR IN A
                PRIVATE ATTORNEY GENERAL CAPACITY. The arbitral tribunal may not consolidate more than one person's
                claims, and may not otherwise preside over any form of a representative or class proceeding. The
                arbitral tribunal has no power to consider the enforceability of this class arbitration waiver and any
                challenge to the class arbitration waiver may only be raised in a court of competent jurisdiction.
              </p>
            </li>
          </ol>
          <p>
            If any provision of this arbitration agreement is found unenforceable, the unenforceable provision will be
            severed and the remaining arbitration terms will be enforced.
          </p>
          <h2>9. Assignment</h2>
          <p>
            You will not assign any of your rights or delegate any of your obligations under these Terms without our
            prior written consent. Any purported assignment or delegation in violation is null and void. No assignment
            or delegation relieves you of any of your obligations under these Terms.
          </p>
          <h2>10. No Waivers</h2>
          <p>
            The failure by us to enforce any right or provision of these Terms will not constitute a waiver of future
            enforcement of that right or provision. The waiver of any right or provision will be effective only if in
            writing and signed by a duly authorized representative of QUANTUMTECH SYSTEMS LTD.
          </p>
          <h2>11. No Third-Party Beneficiaries</h2>
          <p>
            These Terms do not and are not intended to confer any rights or remedies upon any person other than you.
          </p>
          <h2>12. Notices</h2>
          <ol>
            <li>
              <p>
                To You: We may provide any notice to you under these Terms by sending a message to the email address you
                provide or by posting to the Site. Notices sent by email will be effective when we send the email and
                notices we provide by posting will be effective upon posting. It is your responsibility to keep your
                email address current.
              </p>
            </li>
            <li>
              <p>
                To Us: To give us notice under these Terms, you must contact us by personal delivery, overnight courier,
                or registered or certified mail to office@quantumtech.dev. We may update the address for notices to us
                by posting a notice on the Site. Notices provided by personal delivery will be effective immediately.
                Notices provided by overnight courier will be effective one business day after they are sent. Notices
                provided by registered or certified mail will be effective three business days after they are sent.
              </p>
            </li>
          </ol>
          <h2>13. Severability</h2>
          <p>
            If any provision of these Terms is invalid, illegal, void or unenforceable, then that provision will be
            deemed severed from these Terms and will not affect the validity or enforceability of the remaining
            provisions of these Terms.
          </p>
          <h2>14. Entire Agreement</h2>
          <p>
            Our order confirmation, these Terms, our Website Terms of Use, and our Privacy Policy will be deemed the
            final and integrated agreement between you and us on the matters contained in these Terms.
          </p>
        </div>
      </div>
    </div>
  );
};
